
import { MENU_HOME, MENU_AGENCY, MENU_USERS, MENU_OPERATORS, MENU_CONVEYORS, MENU_UNITS, MENU_SERVICES, MENU_RESERVATIONS, MENU_PROFILES, MENU_BANKS, MENU_PROMOTIONS, MENU_CASH_PAYMENT, MENU_PENDING_PAYMENTS, MENU_QUOTE } from "../enums/MenuEnums";

export const ROUTE_LOGIN                    = 'ginPage_L';
export const ROUTE_HOME                     = 'HomePage_'+MENU_HOME;
export const ROUTE_AGENCIES                 = 'AgencyPage_'+MENU_AGENCY;
export const ROUTE_AGENCIESADD              = 'AgencyAddPage_'+MENU_AGENCY;
export const ROUTE_AGENCIESEDIT             = 'AgencyEditPage_'+MENU_AGENCY;
export const ROUTE_AGENCIES_COMMISSIONS     = 'AgencyCommissionsPage_'+MENU_AGENCY;

export const ROUTE_USER_ADMIN                   = 'UserAdminPage_'+MENU_USERS;
export const ROUTE_USER_ADD                     = 'UserAddPage_'+MENU_USERS;
export const ROUTE_USER_EDIT                    = 'UserEditPage_'+MENU_USERS;
export const ROUTE_USER_NOTIFICATIONS           = 'UserNotificationsPage_'+MENU_USERS;
export const ROUTE_OPERATORS                    = 'OperatorsPage_'+MENU_OPERATORS;
export const ROUTE_OPERATOR_ADD                 = 'OperatorAddPage_'+MENU_OPERATORS;
export const ROUTE_OPERATOR_EDIT                = 'OperatorEditPage_'+MENU_OPERATORS;
export const ROUTE_TRANSPORTS                   = 'TransportsPage_'+MENU_CONVEYORS;
export const ROUTE_TRANSPORT_ADD                = 'TransportAddPage_'+MENU_CONVEYORS;
export const ROUTE_TRANSPORT_EDIT               = 'TransportEditPage_'+MENU_CONVEYORS;
export const ROUTE_UNITS                        = 'UnitsPage_'+MENU_UNITS;
export const ROUTE_SERVICE_HOTELS               = 'ServiceHotelsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_CIRCUITS             = 'ServiceCircuitsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_DAYTRIPS             = 'ServiceDaytripsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_TOURS                = 'ServiceTours_'+MENU_SERVICES;
export const ROUTE_SERVICE_INTERNATIONALS       = 'ServiceInternationalsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_LOCKEDS              = 'ServiceLockedsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_ADD_LOCKED           = 'ServiceAddLockedsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_GROUPS               = 'ServiceGroupsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_ADD_GROUP            = 'ServiceAddGroupsPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_ADD                  = 'ServiceAddPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_EDIT                 = 'ServiceEditPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_TRAVEL_DATE          = 'ServiceTravelDatePage_'+MENU_SERVICES;
export const ROUTE_SERVICE_EXIT_PLACES          = 'ServiceExitPlacesPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_EXIT_PLACE_PRICES    = 'ServiceExitPlacePricesPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_TRAVEL_UNIT          = 'ServiceTravelUnitPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_PAYMENT_TO_BROOKER   = 'ServicePaymentToBrookerPage_'+MENU_SERVICES;
export const ROUTE_SERVICE_PAYMENT_GLOBAL_TO_BROOKER   = 'ServicePaymentGlobalToBrookerPage_'+MENU_SERVICES;

export const ROUTE_RESERVATION_HOTEL_INDEX      = 'ReservationHotelIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_HOTEL_SHOW       = 'ReservationHotelShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_INDEX    = 'ReservationCircuitIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_SHOW     = 'ReservationCircuitShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_INDEX   = 'ReservationDaytripsIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_SHOW    = 'ReservationDaytripsShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_INDEX      = 'ReservationToursIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_SHOW       = 'ReservationToursShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_INTERNATIONALS_INDEX     = 'ReservationInternationalsIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_INTERNATIONALS_SHOW      = 'ReservationInternationalsShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_INDEX                = 'ReservationFlightsIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_SHOW                 = 'ReservationFlightsShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_INDEX          = 'ReservationOtherServicesIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_SHOW           = 'ReservationOtherServicesShowPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW      = 'ReservationPaymentToBrookerViewPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENT_TO_BROOKER           = 'ReservationPaymentToBrookerPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_LIST_PAYMENTS                = 'ReservationListPaymentsPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENT_GLOBAL_TO_BROOKER    = 'ReservationPaymentGlobalToBrookerPage_'+MENU_RESERVATIONS;

export const ROUTE_RESERVATION_TRANSFER_INDEX = 'ReservationTransferIndexPage_'+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_TRANSFER_SHOW = 'ReservationTransferShowPage_'+MENU_RESERVATIONS;

export const ROUTE_RESERVATION_PACKAGED_INDEX   = "ReservationPackagedIndex_"+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_PACKAGED_SHOW    = "ReservationPackagedShow_"+MENU_RESERVATIONS;

export const ROUTE_RESERVATION_LOCKED_INDEX   = "ReservationLockedIndex_"+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_LOCKED_SHOW    = "ReservationLockedShow_"+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_GROUP_INDEX    = "ReservationGroupIndex_"+MENU_RESERVATIONS;
export const ROUTE_RESERVATION_GROUP_SHOW     = "ReservationGroupShow_"+MENU_RESERVATIONS;

export const ROUTE_PROFILE_LIST                 = 'ProfilePage_'+MENU_PROFILES;
export const ROUTE_PROFILE_ADD                  = 'ProfileAddPage_'+MENU_PROFILES;
export const ROUTE_PROFILE_EDIT                 = 'ProfileEditPage_'+MENU_PROFILES;

export const ROUTE_BANKS            = "BankPage_"+MENU_BANKS;
export const ROUTE_BANK_ADD         = "BankAddPage_"+MENU_BANKS;
export const ROUTE_BANK_EDIT        = "BankEditPage_"+MENU_BANKS;

export const ROUTE_PROMOTIONS       = "PromotionPage_"+MENU_PROMOTIONS;
export const ROUTE_PROMOTION_ADD    = "PromotionAddPage_"+MENU_PROMOTIONS;
export const ROUTE_PROMOTION_EDIT   = "PromotionEditPage_"+MENU_PROMOTIONS;
export const ROUTE_PROMOTION_FOLDER = "PromotionFolderPage_"+MENU_PROMOTIONS;

export const ROUTE_CASH_PAYMENT     = "CashPaymentPage_"+MENU_CASH_PAYMENT;

export const ROUTE_AGENCIES_EMAIL   = "AgencieEmailPage_"+MENU_AGENCY;
export const ROUTE_AGENCIES_ACTIONS = "AgencieActionPage_"+MENU_AGENCY;
export const ROUTE_AGENCIES_BOXES   = "AgencieBoxesPage_"+MENU_AGENCY;
export const ROUTE_AGENCIES_SAVINGS   = "AgencieSavingsPage_"+MENU_AGENCY;

export const ROUTE_BANK_HISTORY_MOVE_LIST   = "BankHistoryMoveListPage_"+MENU_BANKS;

export const ROUTE_AGENCIES_BOXES_HISTORY = "AgencieBoxesHistoryPage_"+MENU_BANKS;
export const ROUTE_AGENCIES_BOXES_HISTORY_MOVE_LIST = "AgencieBoxesHistoryMoveListPage_"+MENU_BANKS;

export const ROUTE_RESERVATION_PASS_INDEX = 'ReservationPassesPage_'+MENU_RESERVATIONS;

export const ROUTE_PROFILE = 'ProfilePage_'+MENU_HOME;
export const ROUTE_CONFIGURATIONS = 'ConfigurationPage_'+MENU_HOME;
export const ROUTE_PENDING_PAYMENTS = 'ConfigurationPage_'+MENU_PENDING_PAYMENTS;

export const ROUTE_SERVICE_QUOTE = 'ConfigurationPage_'+MENU_QUOTE;
export const ROUTE_SERVICE_QUOTE_VIEW = 'ConfigurationPageView_'+MENU_QUOTE;