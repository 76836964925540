<template>
<!-- Sidebar -->
<div class="sidebar" id="sidebar">
    <div class="sidebar-logo">
        <router-link to="/admin/dashboard">
            <img :src="config.image" class="img-fluid" alt="">
            <!-- <label for="" style="font-size: 1rem;vertical-align: -webkit-baseline-middle;color: white;padding-left: 10px;">{{ config.name }}</label> -->
        </router-link>
    </div>
    <div class="sidebar-inner" v-if="showMenus">
        <vue-custom-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
        <div id="sidebar-menu" class="sidebar-menu"> 
            <ul>
                <li v-bind:class="{'active': currentPath == ROUTE_HOME}" v-if="ACCESS_MENU(MENU_HOME)">
                    <router-link to="/admin/dashboard"><i :class="ACCESS_MENU(MENU_HOME).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_HOME).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_AGENCY)" v-bind:class="{'active': [ROUTE_AGENCIES, ROUTE_AGENCIESADD, ROUTE_AGENCIESEDIT, ROUTE_AGENCIES_COMMISSIONS].includes(currentPath) }">
                    <router-link to="/admin/agencias"><i :class="ACCESS_MENU(MENU_AGENCY).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_AGENCY).menu.title }}</span></router-link>
                </li>
                    <!-- <li class="menu-title"> 
                        <span>Pages</span>
                    </li> -->
                <li v-if="ACCESS_MENU(MENU_OPERATORS)" v-bind:class="{'active': [ ROUTE_OPERATORS, ROUTE_OPERATOR_ADD, ROUTE_OPERATOR_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/operadoras"><i :class="ACCESS_MENU(MENU_OPERATORS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_OPERATORS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_CONVEYORS)" v-bind:class="{'active': [ ROUTE_TRANSPORTS, ROUTE_TRANSPORT_ADD, ROUTE_TRANSPORT_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/transportadoras"><i :class="ACCESS_MENU(MENU_CONVEYORS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_CONVEYORS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_UNITS)" v-bind:class="{'active': [ ROUTE_UNITS ].includes(currentPath)}"> 
                    <router-link to="/admin/unidades"><i :class="ACCESS_MENU(MENU_UNITS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_UNITS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_SERVICES)" class="submenu">
                    <a href="javascript:;" v-bind:class="{'active': [ 
                            ROUTE_SERVICE_HOTELS,ROUTE_SERVICE_CIRCUITS,ROUTE_SERVICE_DAYTRIPS,ROUTE_SERVICE_TOURS,
                            ROUTE_SERVICE_INTERNATIONALS, ROUTE_SERVICE_ADD, ROUTE_SERVICE_EDIT, ROUTE_SERVICE_TRAVEL_DATE,
                            ROUTE_SERVICE_EXIT_PLACES, ROUTE_SERVICE_EXIT_PLACE_PRICES, ROUTE_SERVICE_LOCKEDS, ROUTE_SERVICE_ADD_LOCKED,
                            ROUTE_SERVICE_GROUPS, ROUTE_SERVICE_ADD_GROUP,
                        ].includes(currentPath) }">
                        <i :class="ACCESS_MENU(MENU_SERVICES).menu.icon"></i> 
                        <span> {{ ACCESS_MENU(MENU_SERVICES).menu.title }} </span> 
                        <span class="menu-arrow"></span>
                    </a>
                    <ul style="display: none;">
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_HOTELS ].includes(currentPath) }"><router-link to="/admin/servicios/hoteles"> Hoteles </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_CIRCUITS ].includes(currentPath) }"><router-link to="/admin/servicios/circuitos"> Circuitos </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_DAYTRIPS ].includes(currentPath) }"><router-link to="/admin/servicios/pasadias"> Pasadias </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_TOURS ].includes(currentPath) }"><router-link to="/admin/servicios/tours"> Tours </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_INTERNATIONALS ].includes(currentPath) }"><router-link to="/admin/servicios/internacionales"> Internacionales </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_LOCKEDS, ROUTE_SERVICE_ADD_LOCKED ].includes(currentPath) }"><router-link to="/admin/servicios/bloqueos"> Bloqueos </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_GROUPS, ROUTE_SERVICE_ADD_GROUP ].includes(currentPath) }"><router-link to="/admin/servicios/grupos"> Grupos </router-link></li>
                    </ul>
                </li>
                <!-- Cotizador -->
                <li v-if="ACCESS_MENU(MENU_QUOTE)" v-bind:class="{'active': [ ROUTE_SERVICE_QUOTE].includes(currentPath) }"> 
                    <router-link to="/admin/cotizador"><i :class="ACCESS_MENU(MENU_QUOTE).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_QUOTE).menu.title }}</span></router-link>
                </li>
                <li  v-if="ACCESS_MENU(MENU_RESERVATIONS)" class="submenu"> 

                    <a href="javascript:;" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_HOTEL_INDEX,
                            ROUTE_RESERVATION_HOTEL_SHOW,
                            ROUTE_RESERVATION_CIRCUIT_INDEX,
                            ROUTE_RESERVATION_CIRCUIT_SHOW,
                            ROUTE_RESERVATION_DAYTRIPS_INDEX,
                            ROUTE_RESERVATION_DAYTRIPS_SHOW,
                            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                            ROUTE_RESERVATION_INTERNATIONALS_SHOW,
                            ROUTE_RESERVATION_FLIGHTS_INDEX,
                            ROUTE_RESERVATION_FLIGHTS_SHOW,
                            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                            ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
                            ROUTE_RESERVATION_TRANSFER_INDEX,
                            ROUTE_RESERVATION_TRANSFER_SHOW,
                            ROUTE_RESERVATION_PACKAGED_INDEX,
                            ROUTE_RESERVATION_PACKAGED_SHOW,
                            ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
                            ROUTE_RESERVATION_LOCKED_INDEX,
                            ROUTE_RESERVATION_LOCKED_SHOW,
                            ROUTE_RESERVATION_GROUP_INDEX,
                            ROUTE_RESERVATION_GROUP_SHOW,
                        ].includes(currentPath) }"><i :class="ACCESS_MENU(MENU_RESERVATIONS).menu.icon"></i> <span> {{ ACCESS_MENU(MENU_RESERVATIONS).menu.title }} </span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_HOTEL_INDEX,
                            ROUTE_RESERVATION_HOTEL_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/hoteles">Hoteles </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_CIRCUIT_INDEX,
                            ROUTE_RESERVATION_CIRCUIT_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/circuitos">Circuitos </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_DAYTRIPS_INDEX,
                            ROUTE_RESERVATION_DAYTRIPS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/pasadias">Pasadias </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_TOURS_INDEX,
                            ROUTE_RESERVATION_TOURS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/tours">Tours </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                            ROUTE_RESERVATION_INTERNATIONALS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/internacionales">Internacionales </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_PACKAGED_INDEX,
                            ROUTE_RESERVATION_PACKAGED_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/empaquetados">Empaquetados </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_FLIGHTS_INDEX,
                            ROUTE_RESERVATION_FLIGHTS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/vuelos">Vuelos </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                            ROUTE_RESERVATION_OTHER_SERVICE_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/otros-servicios">Otros Servicios </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_TRANSFER_INDEX,
                            ROUTE_RESERVATION_TRANSFER_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/traslados">Traslados </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_LOCKED_INDEX,
                            ROUTE_RESERVATION_LOCKED_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/bloqueos">Bloqueos </router-link></li>
                        <li v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_GROUP_INDEX,
                            ROUTE_RESERVATION_GROUP_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/grupos">Grupos </router-link></li>
                    </ul>
                </li>
                <li  v-if="ACCESS_MENU(MENU_PROMOTIONS)" v-bind:class="{'active': [ ROUTE_PROMOTIONS, ROUTE_PROMOTION_ADD, ROUTE_PROMOTION_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/promociones"><i :class="ACCESS_MENU(MENU_PROMOTIONS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_PROMOTIONS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_CASH_PAYMENT)" v-bind:class="{'active': [ ROUTE_CASH_PAYMENT ].includes(currentPath)}"> 
                    <router-link to="/admin/pagos-efectivo"><i :class="ACCESS_MENU(MENU_CASH_PAYMENT).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_CASH_PAYMENT).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_PENDING_PAYMENTS)" v-bind:class="{'active': [ ROUTE_PENDING_PAYMENTS ].includes(currentPath)}"> 
                    <router-link to="/admin/pagos-por-confirmar"><i :class="ACCESS_MENU(MENU_PENDING_PAYMENTS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_PENDING_PAYMENTS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_BANKS)" v-bind:class="{'active': [ ROUTE_BANKS, ROUTE_BANK_ADD, ROUTE_BANK_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/cuentas"><i :class="ACCESS_MENU(MENU_BANKS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_BANKS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_USERS)" v-bind:class="{'active': [ROUTE_USER_ADMIN, ROUTE_USER_ADD, ROUTE_USER_EDIT, ROUTE_USER_NOTIFICATIONS].includes(currentPath) }">
                    <router-link to="/admin/administradores"><i :class="ACCESS_MENU(MENU_USERS).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_USERS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(MENU_PROFILES)" v-bind:class="{'active': [ROUTE_PROFILE_LIST, ROUTE_PROFILE_ADD, ROUTE_PROFILE_EDIT].includes(currentPath) }"> 
                    <router-link to="/admin/perfiles"><i :class="ACCESS_MENU(MENU_PROFILES).menu.icon"></i> <span>{{ ACCESS_MENU(MENU_PROFILES).menu.title }}</span></router-link>
                </li>
                <li><a href=""></a></li>
                <li><a href=""></a></li>
                <li><a href=""></a></li>
            </ul>
        </div>
    </vue-custom-scrollbar>
    </div>
</div>
<!-- /Sidebar -->
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import {MENU_HOME, MENU_AGENCY, MENU_OPERATORS, MENU_CONVEYORS, MENU_UNITS, MENU_SERVICES, MENU_RESERVATIONS,
    MENU_USERS, MENU_PROMOTIONS, MENU_BANKS, MENU_PROFILES, MENU_CASH_PAYMENT, MENU_PENDING_PAYMENTS, MENU_QUOTE} from "../../enums/MenuEnums";
import { 
    ROUTE_HOME,
    ROUTE_AGENCIES,
    ROUTE_AGENCIESADD,
    ROUTE_AGENCIESEDIT,
    ROUTE_AGENCIES_COMMISSIONS,
    ROUTE_USER_ADMIN,
    ROUTE_USER_ADD,
    ROUTE_USER_EDIT,
    ROUTE_OPERATORS,
    ROUTE_OPERATOR_ADD,
    ROUTE_OPERATOR_EDIT,
    ROUTE_TRANSPORTS,
    ROUTE_TRANSPORT_ADD,
    ROUTE_TRANSPORT_EDIT,
    ROUTE_UNITS,
    ROUTE_SERVICE_HOTELS,
    ROUTE_SERVICE_CIRCUITS,
    ROUTE_SERVICE_DAYTRIPS,
    ROUTE_SERVICE_TOURS,
    ROUTE_SERVICE_INTERNATIONALS,
    ROUTE_SERVICE_LOCKEDS,
    ROUTE_SERVICE_ADD_LOCKED,
    ROUTE_SERVICE_GROUPS,
    ROUTE_SERVICE_ADD_GROUP,
    ROUTE_SERVICE_ADD,
    ROUTE_SERVICE_EDIT,
    ROUTE_SERVICE_TRAVEL_DATE,
    ROUTE_SERVICE_EXIT_PLACES,
    ROUTE_SERVICE_EXIT_PLACE_PRICES,
    ROUTE_RESERVATION_HOTEL_INDEX,
    ROUTE_RESERVATION_HOTEL_SHOW,
    ROUTE_RESERVATION_CIRCUIT_INDEX,
    ROUTE_RESERVATION_CIRCUIT_SHOW,
    ROUTE_RESERVATION_DAYTRIPS_INDEX,
    ROUTE_RESERVATION_DAYTRIPS_SHOW,
    ROUTE_RESERVATION_INTERNATIONALS_INDEX,
    ROUTE_RESERVATION_INTERNATIONALS_SHOW,
    ROUTE_RESERVATION_FLIGHTS_INDEX,
    ROUTE_RESERVATION_FLIGHTS_SHOW,
    ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
    ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
    ROUTE_RESERVATION_TOURS_INDEX,
    ROUTE_RESERVATION_TOURS_SHOW,
    ROUTE_PROFILE_LIST,
    ROUTE_PROFILE_ADD,
    ROUTE_BANKS, 
    ROUTE_BANK_ADD, 
    ROUTE_BANK_EDIT, 
    ROUTE_PROFILE_EDIT,
    ROUTE_PROMOTIONS,
    ROUTE_PROMOTION_ADD,
    ROUTE_PROMOTION_EDIT,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
    ROUTE_USER_NOTIFICATIONS,
    ROUTE_CASH_PAYMENT,
    ROUTE_PENDING_PAYMENTS,
    ROUTE_RESERVATION_TRANSFER_INDEX,
    ROUTE_RESERVATION_TRANSFER_SHOW,
    ROUTE_RESERVATION_PACKAGED_INDEX,
    ROUTE_RESERVATION_PACKAGED_SHOW,
    ROUTE_SERVICE_QUOTE,
    ROUTE_RESERVATION_LOCKED_INDEX,
    ROUTE_RESERVATION_LOCKED_SHOW,
    ROUTE_RESERVATION_GROUP_INDEX,
    ROUTE_RESERVATION_GROUP_SHOW,
 } from "../../enums/RouteNameEnums";
import { mapGetters } from "vuex";
import { ACCESS_MENU } from "../../validations/auth/AccessControlList";
import $ from 'jquery';

export default {
    components: {
        vueCustomScrollbar
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            config : {},
            showMenus: false,
            activeClass: 'active',

            //MENU IDS
            MENU_HOME, MENU_AGENCY, 
            MENU_OPERATORS, MENU_CONVEYORS, 
            MENU_UNITS, MENU_SERVICES, MENU_RESERVATIONS,
            MENU_USERS, MENU_PROMOTIONS, MENU_BANKS, MENU_PROFILES,MENU_CASH_PAYMENT,
            MENU_PENDING_PAYMENTS,MENU_QUOTE,

            //ROUTE NAMES COMPONENTS
            ROUTE_HOME,
            ROUTE_AGENCIES,
            ROUTE_AGENCIESADD,
            ROUTE_AGENCIESEDIT,
            ROUTE_AGENCIES_COMMISSIONS,
            ROUTE_USER_ADMIN,
            ROUTE_USER_ADD,
            ROUTE_USER_EDIT,
            ROUTE_OPERATORS,
            ROUTE_OPERATOR_ADD,
            ROUTE_OPERATOR_EDIT,
            ROUTE_TRANSPORTS,
            ROUTE_TRANSPORT_ADD,
            ROUTE_TRANSPORT_EDIT,
            ROUTE_UNITS,
            ROUTE_SERVICE_HOTELS,
            ROUTE_SERVICE_CIRCUITS,
            ROUTE_SERVICE_DAYTRIPS,
            ROUTE_SERVICE_TOURS,
            ROUTE_SERVICE_INTERNATIONALS,
            ROUTE_SERVICE_LOCKEDS,
            ROUTE_SERVICE_ADD_LOCKED,
            ROUTE_SERVICE_GROUPS,
            ROUTE_SERVICE_ADD_GROUP,
            ROUTE_SERVICE_ADD,
            ROUTE_SERVICE_EDIT,
            ROUTE_SERVICE_TRAVEL_DATE,
            ROUTE_SERVICE_EXIT_PLACES,
            ROUTE_SERVICE_EXIT_PLACE_PRICES,
            ROUTE_RESERVATION_HOTEL_INDEX,
            ROUTE_RESERVATION_HOTEL_SHOW,
            ROUTE_RESERVATION_CIRCUIT_INDEX,
            ROUTE_RESERVATION_CIRCUIT_SHOW,
            ROUTE_RESERVATION_DAYTRIPS_INDEX,
            ROUTE_RESERVATION_DAYTRIPS_SHOW,
            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
            ROUTE_RESERVATION_INTERNATIONALS_SHOW,
            ROUTE_RESERVATION_FLIGHTS_INDEX,
            ROUTE_RESERVATION_FLIGHTS_SHOW,
            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
            ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
            ROUTE_RESERVATION_TOURS_INDEX,
            ROUTE_RESERVATION_TOURS_SHOW,
            ROUTE_PROFILE_LIST,
            ROUTE_PROFILE_ADD,
            ROUTE_BANKS, 
            ROUTE_BANK_ADD,
            ROUTE_BANK_EDIT,
            ROUTE_PROFILE_EDIT,
            ROUTE_PROMOTIONS,
            ROUTE_PROMOTION_ADD,
            ROUTE_PROMOTION_EDIT,
            ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
            ROUTE_USER_NOTIFICATIONS,
            ROUTE_CASH_PAYMENT,
            ROUTE_PENDING_PAYMENTS,
            ROUTE_RESERVATION_TRANSFER_INDEX,
            ROUTE_RESERVATION_TRANSFER_SHOW,
            ROUTE_RESERVATION_PACKAGED_INDEX,
            ROUTE_RESERVATION_PACKAGED_SHOW,
            ROUTE_SERVICE_QUOTE,
            ROUTE_RESERVATION_LOCKED_INDEX,
            ROUTE_RESERVATION_LOCKED_SHOW,
            ROUTE_RESERVATION_GROUP_INDEX,
            ROUTE_RESERVATION_GROUP_SHOW,
        };
        //  isactive : true
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        ...mapGetters("config", ["configuration"]),
        
        currentPath() {
            return this.$route.name;
        }
    },
    mounted() {
        setTimeout(() => {
            const config = JSON.parse(localStorage.getItem('configuration'));
            if (config !== undefined && config !== null) {
                this.config = config;
            }
            this.showMenus = true;
        }, 2000);
        
        var $wrapper = $('.main-wrapper');
        //var $pageWrapper = $('.page-wrapper');
        //var $slimScrolls = $('.slimscroll');
        
        // Sidebar
        
        var Sidemenu = function() {
            this.$menuItem = $('#sidebar-menu a');
        };
        
        function init() {
            var $this = Sidemenu;
            
            $('#sidebar-menu a').on('click', function(e) {
                if($(this).parent().hasClass('submenu')) {
                    e.preventDefault();
                }
                if(!$(this).hasClass('subdrop')) {
                    $('ul', $(this).parents('ul:first')).slideUp(350);
                    $('a', $(this).parents('ul:first')).removeClass('subdrop');
                    $(this).next('ul').slideDown(350);
                    $(this).addClass('subdrop');
                } else if($(this).hasClass('subdrop')) {
                    $(this).removeClass('subdrop');
                    $(this).next('ul').slideUp(350);
                }
            });
            $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
            $this;
        }
        // Sidebar Initiate
        setTimeout(() => {
            init();
        }, 3500);

    },
    methods: {
        ACCESS_MENU,
        scrollHanle() {
            // console.log(evt)
        }
    },
}
</script>
<style>
.scroll-area {
    position: relative;
    margin: auto;
    height: calc(100vh - 10rem);
    background-color: transparent !important;
    }
    .sidebar-menu ul li a.active {
        background-color: #ff0080 !important;
        color: #fff;
    }
    .sidebar-menu ul ul a.active {
        background-color: unset !important;
        color: #ff0080;
        text-decoration: underline;
    }
</style>